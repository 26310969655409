<template>
    <div class="recordListContainer">
        <!-- 左边 -->
        <div class="recordListLeftContainer">
            <!-- 搜索栏 -->
            <el-input placeholder="输入主播名称搜索" v-model="anchorName" clearable size="small"
                style="width: 180px;margin-top: 16px;" @input="getCompereList">
            </el-input>
            <!-- 主播列表 -->
            <div class="compereListContainer">
                <div v-for="item in compereList" :key="item.id"
                    :class="item.SecUid == currentAnchorSecUid ? 'compereActiveContainer' : 'compereContainer'"
                    @click="compereClickHandle(item.SecUid)">
                    <img :src="item.AnchorAvatar" class="compereAvatar" v-if="item.SecUid != '-1'" />
                    <div class="compereInfoContainer">
                        <div class="compereName">{{ item.AnchorName }}</div>
                        <div class="compereStatusContainer">
                            <div v-if="item.LiveStatus == 0 || item.LiveStatus == -1" class="compereNotPlayStatus">未检测
                            </div>
                            <div v-if="item.LiveStatus == 4" class="compereNotPlayStatus">未开播</div>
                            <div v-if="item.LiveStatus == 2" class="comperePlayStatus">
                                <img src="@/assets/imgs/play.png" class="comperePlayStatusImg">
                                <div class="comperePlayStatusText">直播中</div>
                            </div>
                            <div class="compereResourceType" v-if="item.SecUid != '-1'">
                                <span v-if="item.AnchorPlatform == 'DouYinLive'">抖音</span>
                                <span v-if="item.AnchorPlatform == 'TiktokLive'">tiktok</span>
                                <span v-if="item.AnchorPlatform == 'KuaiShouLive'">快手</span>
                            </div>
                        </div>
                        <div style="font-size: 14px;color: #666;">
                            <span>总：{{ item.RecordTotal + " - " }}</span>
                            <span>今日：{{ item.TodayRecordTotal }}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- 右边 -->
        <div class="recordListRightContainer">
            <!-- 搜索栏 -->
            <div class="searchContainer">
                <el-form :inline="true">
                    <el-form-item label="分析状态">
                        <el-select v-model="analysisStatus" placeholder="请选择" size="mini">
                            <el-option v-for="item in analysisStatusOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="录制日期">
                        <el-date-picker v-model="searchDate" type="daterange" range-separator="~"
                            start-placeholder="开始日期" end-placeholder="结束日期" align="right" size="mini"
                            style="width: 240px;" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-button size="mini" type="primary" plain @click="search">查找</el-button>
                <el-button size="mini" type="danger" plain @click="deleteFile"
                    v-if="selectFileList && selectFileList.length > 0">批量删除</el-button>
            </div>
            <!-- 视频列表 -->
            <el-table v-if="fileList && fileList.length > 0" :data="fileList" size="mini" :max-height="tableMaxHeight"
                ref="videoTable" @selection-change="selectFileTable" row-key="Id">
                <el-table-column type="selection" reserve-selection width="55" align="center">
                </el-table-column>
                <el-table-column label="文件" align="left" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="fileColContainer">
                            <img src="@/assets/imgs/video.png" class="videoImg">
                            <div class="fileNameContainer">
                                <div class="videoNameText">{{ scope.row.LiveTitle }}</div>
                                <div class="compereNameText">{{ scope.row.VideoName.substring(0,
                                    scope.row.VideoName.lastIndexOf(".")) }}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="StartTime" label="录制时间" align="center" width="170">
                    <template slot-scope="scope">
                        <div class="recordColContainer">
                            <div>{{ scope.row.StartTime.substring(0, 16) }}</div>
                            <div style="line-height: 0.6;">~</div>
                            <div>{{ scope.row.EndTime.substring(0, 16) }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="DurationStr" label="时长" align="center" width="110">
                </el-table-column>
                <!-- <el-table-column prop="Paragraph" label="分段" align="center" width="100">
                    <template slot-scope="scope">
                        <div class="fileSizeColContainer">
                            <div>第{{ scope.row.Paragraph + 1 }}段</div>
                        </div>
                    </template>
                </el-table-column> -->
                <el-table-column prop="VedioSizie" label="文件大小" align="center" width="100">
                    <template slot-scope="scope">
                        <div class="fileSizeColContainer">
                            <div>{{ scope.row.VedioSizie + "M" }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="AnalysisStatus" label="分析状态" align="center" width="100">
                    <template slot-scope="scope">
                        <div>{{ ["未分析", "分析中", "分析完成", "分析失败"][scope.row.AnalysisStatus] }}</div>
                        <div style="color: red;" v-if="scope.row.ErrorReason">{{ "(" + scope.row.ErrorReason + ")"
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <div class="operateColContainer">
                            <div class="analysisContainer">
                                <!-- <div v-if="scope.row.AnalysisStatus == 0" class="createAnalysis"
                                    @click="createAnalysis(scope.row.Id, scope.row.SecUid)">生成直播分析</div> -->
                                <div v-if="scope.row.AnalysisStatus == 3" class="createAnalysis"
                                    @click="reAnalysis(scope.row.VideoId, scope.row.SecUid)">重新分析</div>
                                <div v-if="scope.row.AnalysisStatus == 2" class="lookAnalysis"
                                    @click="lockAnalysis(scope.row.VideoId)">查看直播分析</div>
                                <span v-if="scope.row.AnalysisStatus == 1" class="operateText">分析中...</span>
                                <span v-if="scope.row.AnalysisStatus == 0" class="operateText">排队分析</span>
                            </div>
                            <div class="operateBtnContainer">
                                <div class="operateBtn" @click="preview(scope.row.VideoId)">预览</div>
                                <div class="operateBtn" @click="openFolder(scope.row.VideoId)">目录</div>
                                <div v-if="scope.row.AnalysisStatus == 2" class="operateBtn"
                                    @click="reAnalysis(scope.row.VideoId, scope.row.SecUid)">再分析</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div v-else class="emptyContainer">
                <div class="emptyTipText">当前主播还没有录制的视频</div>
            </div>
            <!-- 分页 -->
            <div v-if="fileList && fileList.length > 0">
                <el-pagination style="text-align: center; margin-top: 10px" @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </div>

        <!-- 行业弹窗 -->
        <el-dialog title="行业选择" :visible.sync="tardeDialogVisible" width="600px" :close-on-click-modal="false">
            <el-cascader v-model="tradeIdArr" :options="tradeTreeList" style="width: 100%;"
                :props="{ checkStrictly: true, expandTrigger: 'click', value: 'id', label: 'name' }" filterable
                placeholder="选择一个行业，提高关键词匹配准确性" @change="changeTradeHandle" ref="tradeCascader">
            </el-cascader>
            <span slot="footer" class="dialog-footer">
                <el-button @click="tardeDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="createAnalysisConfirm()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import myUtils from '@/utils/utils.js';
export default {
    data() {
        return {
            selectFileList: [],
            tableMaxHeight: 0,
            tardeDialogVisible: false,
            tradeTreeList: [],
            tradeIdArr: [],
            currentVideoId: "",
            currentSecUid: "",
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            fileList: [],
            searchDate: [],
            resourceTypeList: [
                { value: 0, label: "抖音" },
                { value: 1, label: "快手" },
            ],
            anchorName: "",
            compereList: [],
            currentAnchorSecUid: "-1",
            analysisStatus: -1,
            fileDataForm: {
                pageIndex: 1,
                pageSize: 10,
                anchorId: 0,
                analysisStatus: -1,
                recordStartDate: "",
                recordEndDate: "",
                analysisStartDate: "",
                analysisEndDate: "",
            },
            analysisStatusOptions: [
                {
                    value: -1,
                    label: "全部"
                },
                {
                    value: 0,
                    label: "未分析"
                },
                {
                    value: 1,
                    label: "分析中"
                },
                {
                    value: 2,
                    label: "分析完成"
                },
                {
                    value: 3,
                    label: "分析失败"
                }
            ],
            isReAnalysis: false,
            listTimer: null,
        };
    },

    mounted() {
        this.updateViewportHeight();
        window.addEventListener('resize', this.updateViewportHeight);

        this.getTradeTreeList();
        this.getCompereList();

        // 列表定时器
        if (this.listTimer) {
            clearInterval(this.listTimer);
            this.listTimer = null;
        }
        this.listTimer = setInterval(() => {
            this.getVideoList(true);
        }, 5000);

    },
    beforeDestroy() {
        if (this.listTimer) {
            clearInterval(this.listTimer);
            this.listTimer = null;
        }
    },
    methods: {
        // 选择行业回调
        changeTradeHandle() {
            // 关闭级联列表下拉
            this.$refs.tradeCascader.dropDownVisible = false;
        },
        // 删除文件
        deleteFile() {
            this.$confirm('将永久删除选中的文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let ids = [];
                this.selectFileList.forEach(item => {
                    ids.push(item.VideoId);
                });
                this.$httpClient.video.deletebyids(ids).then(res => {
                    if (res.code == 0 && res.data) {
                        this.selectFileList = [];
                        this.$message.success("删除成功");
                        this.getCompereList();
                        this.getVideoList();
                    } else {
                        this.$message.error("请等待分析结束后再删除");
                    }
                });
            })
        },
        selectFileTable(value) {
            this.selectFileList = value;
        },
        // 计算表格高度
        updateViewportHeight() {
            this.tableMaxHeight = (document.documentElement.clientHeight || document.body.clientHeight) - 190;
        },
        // 搜索
        search() {
            this.pageIndex = 1;
            this.getVideoList();
        },
        // 查看分析结果
        lockAnalysis(id) {
            this.$store.commit("saveVideoAnalysisId", id);
            this.toPage("analysis");
        },
        toPage(url) {
            this.$emit("toPage", url);
        },
        // 获取行业列表树形
        getTradeTreeList() {
            this.tradeTreeList = [];
            this.$httpBack.trade.listTree({}).then((res) => {
                if (res && res.code === 0) {
                    this.tradeTreeList = res.data;
                }
            });
        },
        // 打开目录
        openFolder(videoId) {
            this.$httpClient.video.openFolder({ videoId }).then((res) => {
                if (res.code == 0) {

                }
            });
        },
        // 预览
        preview(videoId) {
            this.$httpClient.video.preview({ videoId }).then((res) => {
                if (res.code == 0) {
                    window.open(res.data, "_blank");
                }
            });
        },
        // 确认生成直播分析
        createAnalysisConfirm() {
            let token = this.$store.state.token;
            let tradeId = "1";
            if (this.tradeIdArr && this.tradeIdArr.length > 0) {
                tradeId = this.tradeIdArr[this.tradeIdArr.length - 1];
                localStorage.setItem('saveAnalysisTrade' + this.currentSecUid, JSON.stringify(this.tradeIdArr));
            } else {
                localStorage.setItem('saveAnalysisTrade' + this.currentSecUid, JSON.stringify(["1"]));
            }

            if (this.isReAnalysis) {
                // 重新分析
                this.$httpClient.video.reanalysis({ videoId: this.currentVideoId, token, tradeId }).then((res) => {
                    if (res.code == 0 && res.data) {
                        this.tardeDialogVisible = false;
                        this.getVideoList();
                        this.$message.success("已重新加入分析排队中等待分析");
                    } else {
                        this.$message.error("请等待上一个分析完再继续");
                    }
                });
            } else {
                // 创建分析
                this.$httpClient.video.createAnalysis({ videoId: this.currentVideoId, token, tradeId }).then((res) => {
                    if (res.code == 0 && res.data) {
                        this.tardeDialogVisible = false;
                        this.getVideoList();
                    } else {
                        this.$message.error("请等待上一个分析完再继续");
                    }
                });
            }

        },
        // 生成直播分析弹窗
        createAnalysis(videoId, secUid) {
            // 设置默认行业
            this.currentSecUid = secUid;
            if (localStorage.getItem("saveAnalysisTrade" + secUid)) {
                this.tradeIdArr = JSON.parse(localStorage.getItem("saveAnalysisTrade" + secUid));
            } else {
                this.tradeIdArr = null;
            }

            this.currentVideoId = videoId;
            this.isReAnalysis = false;


            this.tardeDialogVisible = true;

        },
        // 重新生成分析
        reAnalysis(videoId, secUid) {
            // 设置默认行业
            this.currentSecUid = secUid;
            if (localStorage.getItem("saveAnalysisTrade" + secUid)) {
                this.tradeIdArr = JSON.parse(localStorage.getItem("saveAnalysisTrade" + secUid));
            } else {
                this.tradeIdArr = null;
            }

            this.currentVideoId = videoId;
            this.isReAnalysis = true;
            this.tardeDialogVisible = true;
        },
        // 点击主播的处理事件
        compereClickHandle(secUid) {
            this.pageIndex = 1;
            this.currentAnchorSecUid = secUid;
            this.analysisStatus = -1;
            this.fileList = [];
            this.getVideoList();
        },
        // 获取主播视频列表
        getVideoList(notReset) {

            if (!notReset) {
                this.fileList = [];
            }

            this.fileDataForm = {
                pageIndex: 1,
                pageSize: 10,
                secUid: "",
                analysisStatus: this.analysisStatus,
                recordStartDate: "",
                recordEndDate: "",
                analysisStartDate: "",
                analysisEndDate: "",
            };
            this.fileDataForm.pageIndex = this.pageIndex;
            this.fileDataForm.pageSize = this.pageSize;
            this.fileDataForm.secUid = this.currentAnchorSecUid;
            if (this.searchDate && this.searchDate.length > 0) {
                this.fileDataForm.recordStartDate = this.searchDate[0];
                this.fileDataForm.recordEndDate = this.searchDate[1];
            }

            this.$httpClient.video.getpage(this.fileDataForm).then((res) => {
                if (res.code == 0) {
                    this.totalCount = res.data.Total;
                    this.fileList = res.data.DataList;
                    if (this.fileList && this.fileList.length > 0) {

                        this.fileList.forEach(item => {
                            item.Duration = myUtils.toformatTime(item.Duration * 1000);
                            item.VedioSizie = myUtils.retainDecimals(item.VedioSizie / 1024 / 1024);
                            // 计算时长
                            let second = myUtils.toSecondByDate(item.EndTime) - myUtils.toSecondByDate(item.StartTime);
                            item.DurationStr = myUtils.toformatTimeChinse(second * 1000);

                        });
                    }

                }
            })
        },
        // 获取主播列表
        getCompereList() {
            this.compereList = [];
            this.compereList.push({
                SecUid: "-1",
                AnchorName: "全部",
                RecordTotal: 0,
                TodayRecordTotal: 0
            })

            let requestData = {
                pageIndex: 1,
                pageSize: 500,
                anchorName: this.anchorName ? encodeURIComponent(this.anchorName) : "",
                recordStatus: -1
            }

            this.$httpClient.compere.getpageanchor(requestData).then((res) => {
                if (res.code == 0) {
                    if (res.data && res.data.DataList && res.data.DataList.length > 0) {
                        res.data.DataList.forEach(item => {
                            this.compereList.push(item);
                            this.compereList[0].RecordTotal += item.RecordTotal;
                            this.compereList[0].TodayRecordTotal += item.TodayRecordTotal;
                        });
                        this.getVideoList();
                    }
                }
            })
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getVideoList();
            this.selectFileList = [];
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getVideoList();
            this.selectFileList = [];
        },
    },
};
</script>
<style scoped lang="less">
// /deep/.el-table th.gutter {
//     width: 0 !important;
// }

// /deep/.el-table colgroup col[name='gutter'] {
//     width: 14px !important;
//     // padding: 0 !important;
// }

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 17px; // 横向滚动条
}

/*滚动条凹槽的颜色，还可以设置边框属性 */
/deep/::-webkit-scrollbar-track-piece {
    width: 17px;
}

// 滚动条的滑块
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 8px;
}

/deep/ .el-table--mini .el-table__cell {
    padding: 4px 6px 4px 0;
}

/deep/ .el-table .el-table__cell {
    padding: 4px 6px 4px 0;
}

/deep/ .el-table {
    width: 100%;

    .el-table__header-wrapper table,
    .el-table__body-wrapper table {
        width: 100% !important;
    }

    .el-table__body,
    .el-table__footer,
    .el-table__header {
        table-layout: auto;
    }
}

.emptyTipText {
    font-weight: 400;
    font-size: 14px;
    color: #677583;
}

.emptyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

/deep/ .el-form-item {
    margin-bottom: 0px;
}

/deep/ .el-range-separator {
    width: 10%;
}

.operateBtn {
    width: 50px;
    height: 20px;
    // border-radius: 4px;
    // border: 1px solid #B4BCCA;
    font-size: 14px;
    color: #0077FF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.operateBtnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.lookAnalysis {
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #0077FF;
    font-size: 12px;
    color: #0077FF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.createAnalysis {
    height: 26px;
    line-height: 26px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid rgb(64, 158, 255);
    background-color: rgb(64, 158, 255);
    font-size: 12px;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.analysisContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.operateText {
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
}

.operateColContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fileSizeColContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
}

.recordColContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
}

.compereNameText {
    font-weight: 400;
    font-size: 13px;
    color: #677583;
    margin-top: 4px;
    text-align: start;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // max-width: 260px;
}

.videoNameText {
    font-weight: 400;
    font-size: 14px;
    color: #2E3742;
    text-align: start;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // max-width: 260px;
}

.fileNameContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 12px;
}

.videoImg {
    width: 40px;
    height: 40px;
}

.fileColContainer {
    display: flex;
    align-items: center;
}

.listRowContainer {
    display: flex;
    align-items: center;
    padding: 10px;
}

.tableContainer::-webkit-scrollbar {
    display: none;
}

.tableContainer {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    margin-top: 6px;
}

.listTitleContainer {
    font-weight: 500;
    font-size: 12px;
    color: #2E3742;
    height: 28px;
    background: #F5F7F9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 13px;
    margin-top: 4px;
}

.searchContainer {
    display: flex;
    align-items: center;
}

.recordListRightContainer {
    padding: 16px 10px 16px 28px;
    flex-grow: 1;
    position: relative;
}

.comperePlayStatusText {
    margin-left: 4px;
    font-weight: 500;
    font-size: 13px;
    color: #FF3270;
}

.comperePlayStatusImg {
    width: 18px;
}

.comperePlayStatus {
    display: flex;
    align-items: center;
}

.compereResourceType {
    background: #200B1C;
    border-radius: 8px;
    font-size: 10px;
    color: #FFFFFF;
    text-align: center;
    height: 15px;
    line-height: 15px;
    padding: 0 8px;
    margin-left: 5px;
}

.compereNotPlayStatus {
    font-size: 13px;
    color: #95A1AF;
}

.compereStatusContainer {
    display: flex;
    align-items: center;
    margin-top: 4px;
}

.compereName {
    font-weight: 400;
    font-size: 14px;
    color: #2E3742;
}

.compereInfoContainer {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.compereAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.compereActiveContainer {
    display: flex;
    padding: 10px 0;
    cursor: pointer;
    align-items: center;
    margin-right: 10px;
    background: rgb(245, 247, 250);
    border-radius: 2px;
}

.compereContainer {
    display: flex;
    padding: 10px 0;
    cursor: pointer;
    align-items: center;
    margin-right: 10px;

}

.compereListContainer::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.compereListContainer::-webkit-scrollbar {
    width: 4px;
}


.compereListContainer {
    height: calc(100vh - 91px - 50px);
    overflow-y: auto;
    margin-top: 10px;
}

.recordListLeftContainer {
    width: 200px;
    border-right: 0.5px #DCE0E7 solid;
}

.recordListContainer {
    display: flex;
}
</style>